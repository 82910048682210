<template>
  <el-dialog
    top="30px"
    append-to-body
    :visible.sync="currentShowFlag"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @open="handleDialogOpen"
    @closed="handleDialogClosed"
  >
    <div
      slot="title"
      class="detail-dialog__header"
    >
      {{ $t('summary_afterReport.uploadNewFile') }}
    </div>
    <div>
      <simple-form
        ref="form"
        v-model="formModel"
        label-width="80px"
        :form-field="formField"
        :grid="{xs: 24, sm: 12, md: 8}"
        :view-flag="false"
      />
      <el-button
        type="primary"
        style="margin-left:80px;width:100px;"
        @click="uploadClickHandler"
      >
        {{ $t('operation.uploadFile') }}
      </el-button>
    </div>
    <div slot="footer">
      <el-button @click="currentShowFlag=false">
        {{ $t('operation.cancel') }}
      </el-button>
    </div>
    <simple-upload
      ref="uploader"
      url="/system/attachment/upload"
      :show-flag.sync="isShowUploader"
      @before-upload="beforeUpload"
      @on-success="handleUploadSuccess"
    />
  </el-dialog>
</template>
<script>
const BASEURL = {
  role: '/system/role/list',
  office: '/system/office/list',
  save: '/jlr/tt/auditKpiRowData/save'
}
export default {
  props: {
    showFlag: { type: Boolean, default: false }
  },
  data () {
    return {
      formModel: {},
      isShowUploader: false
    }
  },
  computed: {
    currentShowFlag: {
      get () {
        return this.showFlag
      },
      set (val) {
        this.$emit('update:showFlag', val)
      }
    },
    formField () {
      return [
        {
          prop: 'year',
          type: 'Datepicker',
          label: this.$t('summary_afterReport.year'),
          component: { type: 'year', valueFormat: 'yyyy', clearable: false },
          rules: { trigger: 'blur', message: this.$t('validate.notBlank'), required: true }
        },
        {
          prop: 'period',
          type: 'Select',
          label: this.$t('summary_afterReport.period'),
          component: { optionList: this.$getDictList('calculate_type'), clearable: true },
          rules: { trigger: 'blur', message: this.$t('validate.notBlank'), required: true }
        },
        {
          prop: 'version',
          type: 'Select',
          label: this.$t('summary_afterReport.version'),
          component: { optionList: this.$getDictList('audit_kpi_raw_data_version'), clearable: true },
          rules: { trigger: 'blur', message: this.$t('validate.notBlank'), required: true }
        }
      ]
    }
  },
  inject: ['handleDataSearch'],
  methods: {
    handleDialogOpen () {},
    uploadClickHandler () {
      this.$refs.form.$refs.simpleForm.validate(status => {
        this.isShowUploader = status
      })
    },
    beforeUpload () {},
    handleUploadSuccess (response, file, fileList) {
      const loadingFlag = this.$loading({ fullscreen: false, target: this.$el, text: this.$t('tip.systemLoading') })
      let submitData = this.$_.clone(this.formModel)
      submitData.attachment = { id: response.data.id, fileName: response.data.fileName }
      this.$axios
        .post(BASEURL.save, submitData)
        .then(resp => {
          this.$message({ type: 'success', message: this.$t('tip.uploadSuccess') })
        })
        .finally(() => {
          loadingFlag.close()
          this.isShowUploader = false
          this.currentShowFlag = false
          this.handleDataSearch()
        })
    },
    handleDialogClosed () {
      this.formModel = {}
      this.$refs.form.resetFields()
    }
  }
}
</script>
