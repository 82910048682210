<template>
  <div class="page__wrapper">
    <ag-grid
      style="width:100%;height:100%;"
      :column-defs="columnDefs"
      :row-data.sync="tableData"
      :framework-components="frameworkComponents"
      :grid-options="gridOptions"
    >
      <!-- 查询条件 -->
      <simple-form
        v-model="searchModel"
        :form-field="searchFormField"
      >
        <template slot="tableOperation">
          <el-button @click="handleDataSearch">
            {{ $t('operation.search') }}
          </el-button>
          <el-button
            v-permission="'summary_afterReport:upload'"
            type="primary"
            @click="isShowImportDialog=true"
          >
            {{ $t('summary_afterReport.uploadNewFile') }}
          </el-button>
        </template>
      </simple-form>
    </ag-grid>
    <import-dialog :show-flag.sync="isShowImportDialog" />
  </div>
</template>

<script>
import Vue from 'vue'
import importDialog from './importDialog'

const BASEURL = {
  list: '/jlr/tt/auditKpiRowData/list',
  download: '/system/attachment/download',
  save: '/jlr/tt/auditKpiRowData/save',
  delete: '/jlr/tt/auditKpiRowData/delete'
}

/** 自定义操作列 */
const Operation = Vue.extend({
  inject: ['handleDataSearch', 'handleExportClick', 'handleDeleteClick'],
  template: `<div style="height:100%;text-align:center;">
      <el-tooltip effect="light" :content="$t('operation.download')">
        <el-button type="text" v-permission="'summary_afterReport:download'" style="font-size:14px;" icon="el-icon-download"
          @click="handleExportClick(params.data)"/>
      </el-tooltip>
      <el-tooltip effect="light" :content="$t('operation.delete')">
        <el-button type="text" style="font-size:14px;" icon="el-icon-delete" v-permission="'summary_afterReport:delete'"
          @click="handleDeleteClick(params.data)" />
      </el-tooltip>
    </div>`
})

export default {
  name: 'AfterReport',
  components: { importDialog },
  data () {
    return {
      searchModel: {},
      tableData: [],
      gridOptions: {},
      currentData: {},
      isShowImportDialog: false,
      frameworkComponents: {}
    }
  },
  provide () {
    return {
      handleDataSearch: this.handleDataSearch,
      handleDeleteClick: this.handleDeleteClick,
      handleExportClick: this.handleExportClick
    }
  },
  computed: {
    searchFormField () {
      return [
        {
          prop: 'year',
          type: 'Datepicker',
          col: { xs: 6, sm: 5, md: 4 },
          label: this.$t('summary_afterReport.year'),
          component: { type: 'year', valueFormat: 'yyyy', clearable: true }
        },
        {
          prop: 'period',
          type: 'Select',
          col: { xs: 6, sm: 5, md: 4 },
          label: this.$t('summary_afterReport.period'),
          component: { optionList: this.$getDictList('calculate_type'), clearable: true }
        },
        {
          prop: 'version',
          type: 'Select',
          col: { xs: 6, sm: 5, md: 4 },
          label: this.$t('summary_afterReport.version'),
          component: { optionList: this.$getDictList('audit_kpi_raw_data_version'), clearable: true }
        },
        { slotName: 'tableOperation', col: { xs: 6, sm: 4, md: 12 }, style: { textAlign: 'right' }, labelWidth: '0' }
      ]
    },
    columnDefs () {
      return [
        {
          headerName: this.$t('summary_afterReport.year'),
          field: 'year',
          minWidth: 120
        },
        {
          headerName: this.$t('summary_afterReport.version'),
          field: 'version',
          valueFormatter: params => this.$getDictLabel({ type: 'audit_kpi_raw_data_version', value: params.value }),
          minWidth: 140
        },
        {
          headerName: this.$t('summary_afterReport.name'),
          field: 'attachment.fileName',
          minWidth: 220
        },
        {
          headerName: this.$t('summary_afterReport.period'),
          field: 'period',
          valueFormatter: params => this.$getDictLabel({ type: 'calculate_type', value: params.value }),
          minWidth: 160
        },
        {
          headerName: this.$t('summary_afterReport.uploadTime'),
          field: 'updateDate',
          minWidth: 180
        },
        {
          headerName: this.$t('field.operation'),
          suppressSizeToFit: true,
          width: 80,
          pinned: 'right',
          cellRenderer: 'Operation'
        }
      ]
    }
  },
  beforeMount () {
    this.frameworkComponents = { Operation: Operation }
  },
  mounted () {
    this.handleDataSearch()
  },
  methods: {
    handleDataSearch () {
      const params = Object.assign({}, { page: this.tablePageInfo }, this.$utils.parseQueryCondition(this.searchModel, this.searchFormField))
      const loadingFlag = this.$loading({ fullscreen: false, target: this.$el, text: this.$t('tip.systemLoading') })
      this.$axios
        .post(BASEURL.list, params)
        .then(resp => {
          const respData = resp.data
          this.tableData = respData.list
        })
        .finally(() => {
          loadingFlag.close()
        })
    },
    handleExportClick (row) {
      this.$utils.fileSaveAs(BASEURL.download, { id: row.attachment.id })
    },
    handleDeleteClick (row) {
      this.$msgbox({
        title: this.$t('tip.warning'),
        message: this.$t('tip.confirmDelete'),
        type: 'warning',
        showCancelButton: true,
        closeOnClickModal: false,
        closeOnPressEscape: false,
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true
            this.$axios
              .post(BASEURL.delete, { id: row.id })
              .then(resp => {
                this.$message({ type: 'success', message: this.$t('tip.deleteSuccess') })
                done()
              })
              .finally(() => {
                instance.confirmButtonLoading = false
              })
          } else done()
        }
      })
        .then(() => {
          this.handleDataSearch()
        })
    }
  }
}
</script>
<style lang="less" scoped>
  .page__wrapper {
    width: 100%;
    height: 100%;
  }
</style>
